import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AI = () => {
  return (
    <>
      {/* ================== AI Start ==================*/}
        <div
            className='single-work-process-inner  pd-bottom-120'
        ></div>

        <div
        className='banner-area bg-relative banner-area-2 pb-0 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/banner-5/5.png")' }} id="artificial-intelligence"
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 pb-xl-3 align-self-center'>
              <div className='banner-inner pe-xl-4 pb-5'>
                <h6
                  className='bg-base-2 text-white subtitle'
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                    Intelligence Amplified
                </h6>
                <h2
                  className='title text-black'
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                    Empowering Your Business With <span> Artificial Intelligence </span>
                </h2>

                <Link
                  className='btn btn-border-base-2'
                  data-aos='fade-right'
                  data-aos-delay='450'
                  data-aos-duration='1500'
                  to='/service-details'
                >
                  Discover More <FaPlus />
                </Link>


              </div>
            </div>
            <div className='col-lg-6 col-md-12 align-self-lg-start mb-5 mt-0 '>
              <div className='banner-thumb-2 mt-4 mt-lg-0'>
                <div className='main-img-wrap'>
                  <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='assets/img/banner-5/4.png'
                    alt='img'
                  />

                  <div>
                    <img
                      className='col-lg-12 col-md-12 align-self-lg-start mb-5 mt-0'
                      src='assets/img/banner-5/7.png'
                      alt='img'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== AI End ==================*/}
    </>
  );
};

export default AI;
