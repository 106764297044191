import React from "react";

import {HashLink} from "react-router-hash-link";

const ServiceDetailsArea = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90' id="service-detail-win">
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    All Service lists
                  </h5>
                  <ul className='catagory-items '>
                    <li>
                        <HashLink smooth to={'/service-details#windows'}>
                      Windows Software
                        </HashLink>

                    </li>



                    <li>
                        <HashLink smooth to={'/service-details#ios'}>

                         iOS App
                        </HashLink>

                    </li>
                    <li>
                        <HashLink smooth to={'/service-details#android'}>

                       Android App
                        </HashLink>
                    </li>

                    <li>
                        <HashLink smooth to={'/service-details#web'}>

                        Web Applications
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to={'/service-details#ai'}>

                         Artificial Intelligence
                        </HashLink>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                    <div className='thumb pt-4 pb-4'>
                    <img src='assets/img/service/services.png' alt='img' id="windows" />
                  </div>

                    <div className="text-pri pt-4 pb-4" >


                        <h4>
                        Windows Software
                    </h4>

                        </div>


                    <p>
                        As a software company, we offer comprehensive Windows software development
                        services to our clients. Our team of experienced developers, designers, and
                        project managers works closely with clients to develop software applications
                        that run on Windows operating systems, including Windows 10 and Windows Server.
                        Our Windows software development service includes ideation, conceptualization,
                        design, development, testing, deployment, and maintenance to ensure that clients
                        receive a complete end-to-end solution. We specialize in creating customized software
                        solutions tailored to the specific needs and requirements of each client. Our development
                        process is designed to be flexible, allowing us to adapt to changing needs and priorities
                        throughout the project lifecycle. Our Windows software development services can help
                        businesses and organizations of all sizes to improve their operations, increase productivity,
                        and enhance user experience with software that is tailored to their unique needs..
                    </p>



                    <div className='thumb pt-4 pb-4'>
                        <img src='assets/img/service/4.png' alt='app' id="ios" />
                    </div>

                    <div className="text-primary pt-4 pb-4" >

                    <h4> iOS App</h4>
                    </div>

                    <p>
                        As a software company, we specialize in providing cutting-edge iOS app development services
                        that are designed to meet the unique needs of our clients. Our team of experienced developers,
                        designers, and project managers work together to create iOS apps that are not only visually
                        stunning but also highly functional and optimized for performance. Our iOS app development
                        services include ideation and conceptualization, UI/UX design, development, testing, deployment,
                        and ongoing maintenance to ensure that our clients receive a complete end-to-end solution.
                        We take a collaborative approach to app development, working closely with clients to understand
                        their business objectives and target audience, and translating this into a customized app that
                        reflects their unique brand identity. We also stay up-to-date with the latest trends and technologies
                        in the industry, allowing us to provide our clients with innovative solutions that help them stand
                        out in a crowded marketplace. Overall, our iOS app development services can help businesses and
                        organizations of all sizes to enhance their digital presence and engage with their audience in a
                        more meaningful way.
                    </p>



                    <div className='thumb pt-4 pb-4'>
                        <img src='assets/img/service/6.png' alt='android' id="android" />
                    </div>

                    <div className="text-primary pt-4 pb-4" >

                <h4>Android App</h4>
                    </div>
                      <p>
                          At our software company, we provide top-notch Android app development services to our clients.
                          Our team of expert developers, designers, and project managers have extensive experience in
                          creating high-performance Android apps that are user-friendly and visually appealing. Our Android
                          app development service includes ideation and conceptualization, UI/UX design, development, testing,
                          deployment, and maintenance, ensuring that our clients receive a complete end-to-end solution.
                          We work closely with clients to understand their business objectives and target audience, and
                          translate this into a customized app that reflects their unique brand identity. Our development
                          process is highly collaborative, allowing clients to provide feedback and make changes throughout
                          the project lifecycle. We also stay up-to-date with the latest trends and technologies in the industry,
                          allowing us to provide our clients with innovative solutions that help them stay ahead of the competition.
                          Our Android app development services can help businesses and organizations of all sizes to enhance their
                          digital presence, increase customer engagement, and streamline their operations.
                      </p>

                    <div className='thumb pt-4 pb-4'>
                        <img src='assets/img/service/11.png' alt='img' id='web' />
                    </div>


                    <div className="text-primary pt-4 pb-4" >


<h4 >
    Web Application
</h4>
                      </div>

                      <p>
                          As a software company, we specialize in providing cutting-edge web app development services to our clients.
                          Our team of experienced developers, designers, and project managers work closely with clients to create web
                          apps that are highly functional, visually appealing, and optimized for performance. We understand that
                          web apps are essential for businesses and organizations to achieve their goals and stay competitive in
                          today's digital landscape. That's why we take a customized approach to web app development, tailoring
                          our solutions to meet the unique needs and requirements of each client. Our web app development services
                          include ideation and conceptualization, front-end and back-end development, testing, deployment, and
                          ongoing maintenance to ensure that clients receive a complete end-to-end solution. We use the latest
                          web technologies and programming languages to create web apps that are fast, secure, and scalable.
                          Our collaborative development process involves regular communication and feedback from clients,
                          allowing us to make necessary adjustments and improvements along the way. Overall, our web app
                          development services can help businesses and organizations of all sizes to increase efficiency,
                          streamline processes, and enhance their digital presence.
                      </p>



                    <div className='thumb pt-4 pb-4'>
                        <img src='assets/img/service/ai.png' alt='Artificial intelligence' id='ai' />
                    </div>


                    <div className="text-primary pt-4 pb-4" >

                    <h4>
                        Artificial Intelligence
                    </h4>

                    <p>At our software company, we are at the forefront of providing cutting-edge artificial intelligence
                        (AI) services that are designed to transform the way businesses operate. We have a team of highly
                        skilled AI experts who specialize in developing innovative AI solutions that are tailored to meet
                        the specific needs of our clients. Our AI services include machine learning, natural language processing,
                        computer vision, and predictive analytics, allowing businesses to make data-driven decisions and gain a
                        competitive edge. We work closely with our clients to identify areas where AI can provide the most value,
                        and then develop customized solutions that address their unique challenges. Our AI services are scalable
                        and adaptable, allowing businesses to integrate them seamlessly into their existing systems and processes.
                        With our AI services, businesses can automate routine tasks, improve customer experiences, and streamline
                        operations, leading to increased productivity and profitability. Whether it's developing a chatbot for
                        customer service or implementing predictive analytics for better decision-making, our AI services can
                        help businesses unlock new opportunities and achieve their goals.
                    </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea;
