import React from "react";
import FAQQuestions from "../components/FAQQuestions";

const FAQ = () => {
  return (
    <>


      <FAQQuestions />


    </>
  );
};

export default FAQ;
