import React from "react";
import { Link } from "react-router-dom";

const ScalableSolution = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-60 pd-bottom-120' id="scalable-solution">
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>Scalable Solution<h2 className='title'>
                    Tailored to your needs, built for your
                    <span> SUCCESS</span>

                </h2>
              </div>
            </div>
          </div>
          <div className='row'>

              <div className='col-lg-4 col-md-6' >
              <div className='single-service-inner-2 text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/11.png' alt='img' />
                </div>
                  <div className='details'>
                      <div className='icon mb-3'>
                          <img src='assets/img/service/8.png' alt='img' />
                      </div>
                      <h5>

                    <Link to='/service-details'>Idea Transformation</Link>
                </h5>
                  <p>we specialize in transforming tech ideas into high-quality software
                      solutions. We have a proven track record of turning innovative tech ideas into successful software
                      products, helping businesses to stay ahead of the curve in today's rapidly evolving market.</p>

                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center text-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/5.png' alt='img' />
                </div>
                <div className='details'>
                  <div className='icon mb-3'>
                    <img src='assets/img/service/8.png' alt='img' />
                  </div>
                  <h5>

                    <Link to='/service-details'>Startup Solution</Link>
                  </h5>
                  <p>
                      Empowering startups with innovative and scalable Software Solutions.
                      We understand that startups require unique and tailored solutions that
                      can adapt to their ever-changing needs, which is why we offer a range of
                      flexible software development services

                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center nowrap'>
                <div className='thumb'>
                  <img src='assets/img/service/4.png' alt='img' />
                </div>
                <div className='details'>
                  <div className='icon mb-3'>
                    <img src='assets/img/service/9.png' alt='img' />
                  </div>
                  <h5>
                    <Link to='/service-details'>Enterprise Solution</Link>
                  </h5>
                  <p> Our focus is on constructing software solution for enterprises
                      that fulfill the security and reliability standards required by
                      larger organizations, leading to improved productivity, streamlined workflows,
                      and enhanced visibility and control.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ScalableSolution;
