import React, {useState} from "react";
import {addDoc, collection} from "firebase/firestore";
import {db} from "../firebase";
import {FaPlus} from "react-icons/fa";

const Footer = () => {



    const [name, setName]= useState();
    const [email, setEmail]=useState();


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        console.log("Form submitted");
        console.log(`Name: ${name}`);
        console.log(`Email: ${email}`);

        try {

            const docRef = await addDoc(collection(db, "subscription"), {
                name: name,
                email: email,
            });

            console.log("Document saved with ID: ", docRef.id);

            alert('Subscribed Successfully');
        } catch (e) {
            alert("Error: Please Contact info@zetasp.com");
        }
    }





  return (
    <>
      {/* ================== Footer Four Start ==================*/}
      <footer
        className='footer-area bg-cover mt-0 pd-top-120'
        style={{ backgroundImage: 'url("assets/img/bg/14.png")' }} id="footer"
      >
        <div className='footer-subscribe'>
          <div className='container'>
            <div className='footer-subscribe-inner box-shadow style-2 bg-white p-0 bg-cover'>
              <div className='media bg-base-2'>
                <div className='media-left me-3 align-self-xl-center'>
                  <img src='assets/img/icon/40.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h4 className='text-white'>Subscribe to Newsletter</h4>
                </div>
              </div>
              <div className='row align-self-center'>
                <div className='col-lg-4'>
                  <div className='border-1'>
                    <input type='text' placeholder='Your Name'
                           onChange={(e) => setName(e.target.value)}



                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='border-1'>
                    <input type='text' placeholder='Your Email'
                           onChange={(e) => setEmail(e.target.value)}

                    />
                  </div>
                </div>
                <div className='col-lg-4'>

                    <button type='submit' onClick={handleFormSubmit} className="btn w-100 btn-black sky border-radius-0">Submit <FaPlus /> </button>

                </div>
              </div>
            </div>
          </div>
        </div>


              <div className='col-md-12 text-lg-end'>
                  <p>© Zetasp 2023 | All Rights Reserved</p>

              </div>

      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default Footer;
