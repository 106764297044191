import React from "react";
import Services from "../components/Services";
import HomePage from "../components/HomePage";
import TechnologyPartners from "../components/TechnologyPartners";

import ScalableSolution from "../components/ScalableSolution";
import Footer from "../components/Footer";
import AI from "../components/AI";
import WorkProcess from "../components/WorkProcess";
import ContactUs from "../components/ContactUs";

import PartnerLogo from "../components/PartnerLogo";
import NavigationBar from "../components/NavigationBar";
import FAQ from "./FAQ";



const Home = () => {
  return (
    <>
      <NavigationBar />
      <HomePage />
      <Services />
        <ScalableSolution />
        <WorkProcess />
        <AI  />
        <TechnologyPartners />
        <PartnerLogo/>
        <FAQ />
    <ContactUs/>
      <Footer />
    </>
  );
};

export default Home;
