import React from "react";
const PartnerLogo = () => {
  return (
    <div className='about-area bg-white pt-0 pb-5' id="partner-Logo">



          {/*------------------------Starting from here ---------------------*/}
          <div className='row'>

              {/*--------------------------------*/}
              <div className='col'>
                <div className='thumb m-5 text-center'>
              <img src='assets/img/client/swift.png' alt='swift' />
                    <div className='text-black  mt-3'>
              <h5>
                  Swift
              </h5>
                </div>
          </div>
              </div>
              {/*--------------------------------*/}

              <div className='col'>

                  <div className='thumb m-5 text-center '>
                      <img src='assets/img/client/android.png' alt='android' />
                      <div className='text-black  mt-3'>
                      <h5>
                          Android
                      </h5>
                      </div>

                  </div>
              </div>
              {/*--------------------------------*/}

              <div className='col'>

                  <div className='thumb m-5 text-center '>
                      <img src='assets/img/client/react.png' alt='react native' />
                      <div className='text-black  mt-3'>
                          <h5>
                             React Native
                          </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}


              <div className='col'>

                  <div className='thumb m-5 text-center '>
                      <img src='assets/img/client/flutter.png' alt='flutter' />
                      <div className='text-black  mt-3'>
                          <h5>
                             Flatter
                          </h5>
                      </div>
                  </div>
              </div>
          </div>

        {/*------------------------Second Row --------------*/}

          <div className='row'>

              {/*--------------------------------*/}
              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/c-.png' alt='c++' />
                      <div className='text-black  mt-3'>

                      <h5>
                          C++
                      </h5>
                      </div>
                  </div>
              </div>
              {/*--------------------------------*/}

              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/c-sharp.png' alt='C-charp' />
                      <div className='text-black  mt-3'>

                          <h5>
                              C#
                          </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}

              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/java.png' alt='java' />
                      <div className='text-black  mt-3'>

                          <h5>
                              Java
                          </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}


              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/python.png' alt='python' />
                      <div className='text-black  mt-3'>

                          <h5>
                              Python
                          </h5>
                      </div>
                  </div>
              </div>

          </div>

          {/*-------------Second Row Finsh Here-------------------*/}



          <div className='row'>

              {/*--------------------------------*/}
              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/angular.png' alt='angular' />
                      <div className='text-black  mt-3'>

                          <h5>
                             Angular
                          </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}

              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/js.png' alt='Java Script' />
                      <div className='text-black  mt-3'>

                          <h5>
                             Java Script
                          </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}

              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/php.png' alt='php' />
                      <div className='text-black  mt-3'>

                          <h5>
                             PHP
                          </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}


              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/laravel.png' alt='Laravel' />
                      <div className='text-black  mt-3'>

                          <h5>
                             Laravel
                          </h5>
                      </div>
                  </div>
              </div>

          </div>

          {/*-------------Third Row Finsh Here-------------------*/}



          <div className='row'>

              {/*--------------------------------*/}
              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/docker.png' alt='Docker' />
                      <div className='text-black  mt-3'>

                          <h5>
                             Docker
                          </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}

              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/tensor.png' alt='Tensor' />
                      <div className='text-black  mt-3'>

                          <h5>
                             Tensor
                          </h5>
                      </div>
                  </div>
              </div>
              {/*--------------------------------*/}

              <div className='col'>

                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/mongo.png' alt='Mongo db' />
                      <div className='text-black  mt-3'>

                      <h5>
                          Mongo DB
                      </h5>
                      </div>
                  </div>
              </div>

              {/*--------------------------------*/}



              <div className='col'>
                  <div className='thumb m-5 text-center'>
                      <img src='assets/img/client/mysql.png' alt='MYSQL' />
                      <div className='text-black  mt-3'>

                          <h5>
                             MySQL
                          </h5>
                      </div>
                  </div>
              </div>

          </div>

          {/*-------------Fourth Row Finsh Here-------------------*/}

    </div>



  );
};

export default PartnerLogo;
