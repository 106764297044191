import React, {useState} from "react";
import { FaPlus } from "react-icons/fa";

import { collection, addDoc } from "firebase/firestore";
import {db} from '../firebase';

const ContactUs = () => {

    const [name, setName]= useState();
    const [phone, setPhone]= useState();
    const [email, setEmail]= useState();
    const [message, setMessage]= useState();




    const handleFormSubmit = async (e) => {
        e.preventDefault();

        console.log("Form submitted");
        console.log(`Name: ${name}`);
        console.log(`Phone: ${phone}`);
        console.log(`Email: ${email}`);
        console.log(`Message: ${message}`);

        try {

            const docRef = await addDoc(collection(db, "formSubmission"), {
                name: name,
                phone: phone,
                email: email,
                message: message,
            });

            console.log("Document saved with ID: ", docRef.id);

            alert('Message Sent Successfully');
        } catch (e) {
            alert("Message couldn't be sent. Contact info@zetasp.com");
        }
    }





  return (
    <>
      {/*================= counter area start {/*=================*/}
      <div className='contact-area pd-top-90 pd-bottom-120' id="contact-us">
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 order-lg-end '
              data-aos='fade-left'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner ms-xl-5 p-xl-5 pt-4 '>
                <img
                  className='animate-main-img'
                  src='assets/img/about/18.png'
                  alt='img'
                />
                <img
                  className='main-img m-4'
                  src='assets/img/about/Contact.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 order-lg-first '
              data-aos='fade-right'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-5 mt-lg-0'>
                <h6 className='sub-title'>GET IN TOUCH</h6>
                <h2 className='title'>
                  Let's build something <span>Great </span> together

                </h2>

                  <div className='mt-4'>

                      <h3 className='title' >
                          <p >
                              <span>info@zetasp.com</span>

                          </p>
                      </h3>
                  </div>

                <p className='content'>
                  Whether you're curious, have a question, or need more information.
                    <p> We're ready to answer any and all questions.</p>

                </p>
                  <form className="mt-4" onSubmit={handleFormSubmit}>
                      <div className="row">
                          <div className="col-lg-6">
                              <div className="single-input-inner style-border">
                                  <input
                                      type="text"
                                      placeholder="Name"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                      required
                                  />
                              </div>
                          </div>
                          <div className="col-lg-6">
                              <div className="single-input-inner style-border">
                                  <input
                                      type="number"
                                      placeholder="Phone"
                                      value={phone}
                                      onChange={(e) => setPhone(e.target.value)}
                                      required
                                  />
                              </div>
                          </div>
                          <div className="col-lg-12">
                              <div className="single-input-inner style-border">
                                  <input
                                      type="email"
                                      placeholder="Email"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      required
                                  />
                              </div>
                          </div>
                          <div className="col-lg-12">
                              <div className="single-input-inner style-border">
                        <textarea
                            placeholder="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                              </div>
                          </div>
                          <div className="col-12">
                              <button type='submit' className="btn btn-border-gray mt-0">Submit <FaPlus /> </button>
                          </div>


                      </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </>
  );
};

export default ContactUs;
