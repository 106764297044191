import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      {/* =============== FAQ Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120' id='Services'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/10.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title' id='start'>On-Demand</h6>
                <h2 className='title'>
                    End-to-End Mobile App Development Services
                </h2>
                <p className='content mb-4'>
                    Our mobile application development services are tailored to help your app idea flourish
                    into a thriving business. With a broad range of offerings, we provide the necessary support
                    to ensure your app achieves its full potential and experiences sustained growth.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> iOS App Development
                      </li>
                      <li>
                        <FaCheckCircle /> Android App Development
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> React Native App Development
                      </li>
                      <li>
                        <FaCheckCircle /> Flutter App Development
                      </li>
                    </ul>
                  </div>
                </div>
                <p className='content'>
                    Our experienced app developers specialize in delivering tailored,
                    high-quality mobility solutions that align with your specific business needs,
                    whether you require cross-platform or native apps. Our aim is to meet your unique
                    business requirements and ensure your satisfaction with our services.
                </p>
                <Link className='btn btn-border-base' to='/service-details' >
                  Discover More <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== FAQ Area Two End ===============*/}
    </>
  );
};

export default Services;
