import React from "react";
import {  FaPlus } from "react-icons/fa";

const TechnologyPartners = () => {
  return (
    <>
      {/*===================== Blog Area Two start =====================*/}
      <div className='blog-area pd-top-120 pd-bottom-60' id="technology-partners">
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='section-title text-center'>

                <h2 className='title'>
                    Technology Partners
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog-list style-2 '>
                <div className='thumb'>
                  <img src='assets/img/blog/10.png' alt='img' />
                </div>
                <div className='details'>

                  <h5 className='mb-3'>
                      <a href="https://aws.amazon.com/?nc2=h_lg" target="_blank" rel="noopener noreferrer" >
                        Amazon Web Services
                    </a>
                  </h5>
                    <a href="https://aws.amazon.com/?nc2=h_lg" target="_blank" rel="noopener noreferrer" >
                    Discover More <FaPlus />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog-list style-2'>
                <div className='thumb'>
                  <img src='assets/img/blog/11.png' alt='img' />
                </div>
                <div className='details'>

                  <h5 className='mb-3'>
                      <a href="https://cloud.google.com/" target="_blank" rel="noopener noreferrer" >
                        Google Cloud Platform
                    </a>
                  </h5>
                    <a href="https://cloud.google.com/" target="_blank" rel="noopener noreferrer" >
                    Discover More  <FaPlus />
                  </a>

                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog-list style-2'>
                <div className='thumb'>
                  <img src='assets/img/blog/12.png' alt='img' />
                </div>
                <div className='details'>

                  <h5 className='mb-3'>
                      <a href="https://azure.microsoft.com/en-us/" target="_blank" rel="noopener noreferrer" >
                        Microsoft Azure
                    </a>
                  </h5>
                    <a href="https://azure.microsoft.com/en-us/" target="_blank" rel="noopener noreferrer" >
                    Discover More <FaPlus />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== TechnologyPartners End =====================*/}
    </>
  );
};

export default TechnologyPartners;
