import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServiceDetailsArea from "../components/ServiceDetailsArea";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import NavigationBar from "../components/NavigationBar";

const ServiceDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavigationBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Services"} />

      {/* Service Details Area */}
      <ServiceDetailsArea />

        <ContactUs />

        {/* Footer One */}
      <Footer />
    </>
  );
};

export default ServiceDetails;
