import React from "react";

const WorkProcess = () => {
  return (
    <>
      {/*==================== Work Process Three start ====================*/}

        <div
        className='work-process-area bg-position-right pd-top-120 pd-bottom-120'
        style={{ backgroundImage: 'url("./assets/img/bg/16.png")' }}
        id='work-process'
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center pb-5'>
                <h6 className='sub-title-sky-blue'>WORK PROCESS</h6>
                <h2 className='title'>
                    From concept to code: building software that exceeds expectations                </h2>
              </div>
            </div>
          </div>
          <div className='work-process-area-inner-2'>
            <div className='row'>
              <div className='col-lg-3 col-md-6 bg-white'>
                <div className='single-work-process-inner style-2 text-center mb-xl-5'>

                  <div className='thumb mb-3'>
                    <img src='assets/img/icon/36.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Idea Validation & Planning</h5>
                    <p className='content'>
                        We set your foundation right. By validating your ideas through
                         research and planning.

                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 mt-xxl-5 bg-white '>
                <div className='single-work-process-inner style-2 text-center'>

                  <div className='thumb mb-3'>
                    <img src='assets/img/icon/37.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Prototype & Design</h5>
                    <p className='content'>
                        We build and design prototype that aligns with your project requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 bg-white'>
                <div className='single-work-process-inner style-2 text-center'>

                  <div className='thumb mb-3'>
                    <img src='assets/img/icon/38.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Build & Test</h5>
                    <p className='content'>
                        We build using a unique experience by putting brand, functionality, quality, easability, and usability together.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 mt-xxl-5 bg-white'>
                <div className='single-work-process-inner style-2 text-center'>
                  <div className='thumb mb-3'>
                    <img src='assets/img/icon/39.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Launch & Deployment</h5>
                    <p className='content'>
                        We will assist you in launching your app by utilizing the most effective strategies and industry practices.

                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcess;
