import React from "react";

const FAQQuestions = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className='faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }} id="faq-questions"
      >
        <div className='container'>
          <div className='row pd-top-120'>
            <div
              className='col-xl-5 col-lg-6 col-md-8 order-lg-last'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner pt-5 m-5 col-lg-10 '>
                <img
                  className='main-img'
                  src='assets/img/about/FAQ.png'
                  alt='img'
                />

              </div>
            </div>
            <div
              className='col-xl-7 col-lg-6'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h6 className='sub-title'>FAQs</h6>
                <h2 className='title'>
                    <span> Answer </span> to your Questions
                </h2>

              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      What services do you offer?
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                        We offer a full range of software development services,
                        including ideation and conceptualization, design, development,
                        testing, deployment, and maintenance. We can develop native apps for Windows,
                        iOS, Android, and web.

                    </div>
                  </div>
                </div>

                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      How long does it take for you to complete a project?
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                        he timeline for app development depends on a variety of factors,
                        such as the complexity of the app, the features required, and the platform(s)
                        it will be developed for. We work closely with our clients to determine project
                        timelines and milestones based on their specific needs and goals.
                    </div>
                  </div>
                </div>
                  {/*Addition adding*/}

                  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                        How much does it cost to develop an app?
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                        The cost of app development can vary widely depending on the same
                        factors as timeline, including the complexity of the app, its features,
                        and the platform(s) it will be developed for. We offer competitive pricing and
                        work with our clients to develop custom solutions that fit within their budget.
                    </div>
                  </div>
                </div>
                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingFour'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseFour'
                              aria-expanded='false'
                              aria-controls='collapseFour'
                          >
                              What platforms do you develop apps for?
                          </button>
                      </h2>
                      <div
                          id='collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              We can develop native apps for Windows, iOS and Android,
                              as well as hybrid apps and web apps that work on multiple platforms.

                          </div>
                      </div>
                  </div>




                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingFive'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseFive'
                              aria-expanded='false'
                              aria-controls='collapseFive'
                          >
                              Do you provide maintenance and support after the app is launched?
                          </button>
                      </h2>
                      <div
                          id='collapseFive'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFive'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              Yes, we offer ongoing maintenance and support
                              services to ensure that our clients' apps continue to
                              perform well and meet their evolving needs over time.
                              We also provide regular updates and upgrades to keep the app
                              up-to-date with the latest technologies and user needs.


                          </div>
                      </div>
                  </div>
                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingSix'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseSix'
                              aria-expanded='false'
                              aria-controls='collapseSix'
                          >
                              How do you ensure the security of the app and user data?
                          </button>
                      </h2>
                      <div
                          id='collapseSix'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingSix'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              We take security very seriously and follow best practices for app development a
                              nd data protection. We use secure coding practices, perform regular security audits,
                              and ensure that user data is protected through encryption and other measures.

                          </div>
                      </div>
                  </div>



                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingSeven'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseSeven'
                              aria-expanded='false'
                              aria-controls='collapseSeven'
                          >
                              Can you help with app marketing and promotion?
                          </button>
                      </h2>
                      <div
                          id='collapseSeven'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingSeven'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              Yes, we offer app marketing and promotion services to
                              help our clients get their app in front of the right audience
                              and drive user engagement and retention. Our team can help
                              with app store optimization (ASO), social media marketing,
                              paid advertising, and other strategies.
                          </div>
                      </div>
                  </div>
                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingEight'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseEight'
                              aria-expanded='false'
                              aria-controls='collapseEight'
                          >
                              How do I get started with app development?
                          </button>
                      </h2>
                      <div
                          id='collapseEight'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingEight'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              To get started with app development, simply contact us info@zetasp.com and tell us
                              about your app idea and requirements. We'll schedule a consultation
                              to discuss your project in more detail and provide you with a customized
                              proposal and project timeline.

                          </div>
                      </div>
                  </div>




                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingNine'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseNine'
                              aria-expanded='false'
                              aria-controls='collapseNine'
                          >
                              What is your development process like?
                          </button>
                      </h2>
                      <div
                          id='collapseNine'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingNine'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              Our development process involves several stages, including ideation
                              and conceptualization, design, development, testing, deployment, and
                              maintenance. We follow industry best practices and work closely with our
                              clients to ensure that their app meets their specific needs and goals.



                          </div>
                      </div>
                  </div>
                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTen'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseTen'
                              aria-expanded='false'
                              aria-controls='collapseTen'
                          >
                              Can you develop apps for startups?
                          </button>
                      </h2>
                      <div
                          id='collapseTen'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTen'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              Yes, we specialize in developing apps for startups
                              and work with entrepreneurs and small businesses to help bring their app ideas to life.

                          </div>
                      </div>
                  </div>



                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingEleven'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseEleven'
                              aria-expanded='false'
                              aria-controls='collapseEleven'
                          >
                              Do you provide app design services?
                          </button>
                      </h2>
                      <div
                          id='collapseEleven'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingEleven'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              es, we offer app design services, including UI/UX design,
                              prototyping, and wireframing. Our designers work closely with
                              our clients to create custom designs that are both functional and visually appealing.
                          </div>
                      </div>
                  </div>
                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTwelve'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseTwelve'
                              aria-expanded='false'
                              aria-controls='collapseTwelve'
                          >
                              What programming languages do you use for app development?
                          </button>
                      </h2>
                      <div
                          id='collapseTwelve'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwelve'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              We use a variety of programming languages for
                              app development, including Swift and Objective-C
                              for iOS development, Java and Kotlin for Android, C, C++, Python development,
                              and HTML, CSS, and JavaScript for web and hybrid app development as well as
                              frameworks such as react, angular and flutter, .



                          </div>
                      </div>
                  </div>




                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingThirteen'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseThirteen'
                              aria-expanded='false'
                              aria-controls='collapseThirteen'
                          >
                              Can you integrate third-party APIs and services into my app?
                          </button>
                      </h2>
                      <div
                          id='collapseThirteen'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThirteen'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              Yes, we can integrate third-party APIs and services into your app
                              to add functionality and enhance user experience. We work with a wide
                              range of APIs, including social media, payment, and location-based services.


                          </div>
                      </div>
                  </div>
                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingFourteen'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseFourTeen'
                              aria-expanded='false'
                              aria-controls='collapseFourTeen'
                          >
                              How do you ensure the app is user-friendly?
                          </button>
                      </h2>
                      <div
                          id='collapseFourTeen'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFourteen'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              We focus on creating user-friendly apps that are easy to
                              navigate and use. Our designers conduct user research and testing
                              to ensure that the app meets user needs and preferences, and we continually
                              gather feedback throughout the development process to make improvements.

                          </div>
                      </div>
                  </div>



                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingSixteen'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseSixteen'
                              aria-expanded='false'
                              aria-controls='collapseSixteen'
                          >
                              Can you provide analytics and user insights for my app?
                          </button>
                      </h2>
                      <div
                          id='collapseSixteen'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingSixteen'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              Yes, we can provide analytics and user insights for your
                              app to help you track user behavior, engagement, and retention.
                              We use a variety of analytics tools to gather data and provide customized reports.


                          </div>
                      </div>
                  </div>
                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingSevenTeen'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseSeventeen'
                              aria-expanded='false'
                              aria-controls='collapseSeventeen'
                          >
                              How do you ensure the app meets App Store and Play Store guidelines?
                          </button>
                      </h2>
                      <div
                          id='collapseSeventeen'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingSevenTeen'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              We are familiar with App Store and Play Store guidelines and ensure that our
                              apps comply with their requirements. We also help our clients with the app
                              submission process and provide ongoing support to ensure that their app stays up-to-date
                              with any changes to the guidelines.

                          </div>
                      </div>
                  </div>



                  <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingEighteen'>
                          <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseEighteen'
                              aria-expanded='false'
                              aria-controls='collapseEighteen'
                          >
                              Do you provide app testing services?
                          </button>
                      </h2>
                      <div
                          id='collapseEighteen'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingEighteen'
                          data-bs-parent='#accordionExample'
                      >
                          <div className='accordion-body'>
                              Yes, we provide app testing services to ensure that
                              the app functions properly and meets user needs. Our testing
                              process includes both manual and automated testing to identify
                              and fix any issues before the app is released.

                              .
                          </div>
                      </div>
                  </div>



              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FAQQuestions;
