import { BrowserRouter, Routes, Route } from "react-router-dom";
import FAQ from "./pages/FAQ";

import Home from "./pages/Home";
import ServiceDetails from "./pages/ServiceDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import Services from "./components/Services";
import ScalableSolution from "./components/ScalableSolution";
import AI from "./components/AI";
import TechnologyPartners from "./components/TechnologyPartners";
import ContactUs from "./components/ContactUs";
import WorkProcess from "./components/WorkProcess";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<FAQ />} />

          <Route exact path='/services' element={<Services />} />
          <Route exact path='/service-details' element={<ServiceDetails />} />
          <Route exact path='/scalable-solution' element={< ScalableSolution/>} />
          <Route exact path='/AI' element={< AI />} />
          <Route exact path='/technology-partners' element={< TechnologyPartners />} />
          <Route exact path='/contact-us' element={< ContactUs/>} />
          <Route exact path='/work-process' element={< WorkProcess/>} />
          <Route exact path='/faq' element={< FAQ/>} />



      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
